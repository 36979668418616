<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "@/stores/index";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from 'vue-router';
import Spinner from "@/components/Spinner.vue";
import sweetAlertService from "@/services/sweetAlertService";
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import * as Yup from 'yup';

const body = document.getElementsByTagName("body")[0];

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});


const authStore = useAuthStore();
const form = ref({ email: '', password: ''});
const errors = ref({});

const router = useRouter();

//const alert = sweetAlertService();

const store = useStore();
const toggleDefaultLayout = () => store.toggleDefaultLayout();

onBeforeMount(() => {
  store.hideConfigButton = true;
  toggleDefaultLayout();
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.hideConfigButton = false;
  toggleDefaultLayout();
  body.classList.add("bg-gray-100");
});

const handleSubmit = async () => {
  try {
    await loginSchema.validate(form.value, { abortEarly: false });
    await authStore.login(form.value.email, form.value.password);
    form.value.email = '';
    form.value.password = '';
    // Redirect or show success message
    router.push('/dashboards/dashboard-default');
  } catch (err) {
    if (err.inner) {
      errors.value = {};
      err.inner.forEach((error) => {
        errors.value[error.path] = error.message;
        sweetAlertService.showError(
         'Validation Error',
          error.message,
        );
      });
    } else {
      // console.log(authStore.error);
      sweetAlertService.showError(
       'Login Failed',
        authStore.error || 'An unexpected error occurred.',
      );
    }
  }
};
// Call: <button @click="handleLogout">Logout</button>
//
// // Handle logout
// const handleLogout = async () => {
//   await authStore.logout();
//   // Optionally redirect or show a message
// };

</script>

<template>
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div> -->
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <img src="../../../assets/img/logo-nurture.png" width="50"/> 
                  <h6 style="display: inline;">Nurture Investors Portal</h6>
                  <!-- <h6 class="font-weight-bolder mt-4">Sign In</h6> -->
                  <p class="mb-0 mt-3">Enter your email & password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="handleSubmit">
                    <div class="mb-3">
                      <argon-input
                        v-model="form.email"
                        :schema="loginSchema.fields.email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                      />
                      <span class="text-danger" v-if="errors.email">{{ errors.email }}</span>
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="form.password"
                        :schema="loginSchema.fields.password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                      />
                      <span v-if="errors.password" class="text-danger">{{ errors.password }}</span>
                    </div>
                    <!-- <argon-switch id="rememberMe" name="rememberMe">
                      Remember me
                    </argon-switch> -->
                    <div class="text-center">
                      <argon-button
                      type="submit"
                        class="mt-4"
                        variant="gradient"
                        color="warning"
                        full-width
                        size="lg"
                        >Sign in</argon-button
                      >
                    </div>
                  </form>
                  <Spinner :isLoading="authStore.isLoading" />
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Signup Illustration' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p>
                </div> -->
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="login-bg position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                >
                <span class="mask bg-gradient-dark opacity-6"></span>

                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Patience is the new currency"
                </h4>
                <p class="text-white position-relative">
                  “The stock market is a device to transfer money from the impatient to the patient.”
                </p>
                <p class="text-white position-relative"><i>~Warren Buffett</i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>

.login-bg {
  background: url('@/assets/img/login-bg.jpg');
  background-size: cover;
}

</style>