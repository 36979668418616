<script setup>
import { ref, onMounted } from 'vue';
import { useNewsUpdatesStore } from '@/stores/newsUpdates';
import { DataTable } from 'simple-datatables';
import Spinner from '@/components/Spinner.vue';
import Modal from '@/components/Modal.vue';

// import { useRouter } from 'vue-router'; // Import useRouter

const store = useNewsUpdatesStore();
const isModalVisible = ref(false);
const selectedArticle = ref(null);
//const pdfUrl = ref(null); // State to hold the PDF URL
// const router = useRouter(); // Get the router instance

// Define the base URL
// const baseUrl = process.env.VUE_APP_BASE_URL;

// Define the viewDocument function
// const viewDocument = (url) => {
//   const fullUrl = `${baseUrl}${url.startsWith('/') ? url : '/' + url}`; // Ensure correct URL formation
//   console.log('Viewing document:', fullUrl); // Log the full URL to check if it's correct
//   pdfUrl.value = fullUrl; // Set the PDF URL to display
// };


const modalFields = [
    { key: 'publishedAt', label: 'Published At' },
    { key: 'message', label: 'Message' }
];
const openModal = (newsUpdate) => {
  selectedArticle.value = newsUpdate;
  isModalVisible.value = true;
};
const closeModal = () => {
  isModalVisible.value = false;
  selectedArticle.value = null;
};

onMounted(async () => {
  await store.fetchNewsUpdates();

  // Initialize DataTable after data is fetched
  const dataTableBasic = new DataTable("#datatable-basic", {
    searchable: true,
    fixedHeight: true,
  });

  if (store.newsUpdates && store.newsUpdates.length > 0) {

    store.newsUpdates.forEach((newsUpdate) => {

      const portfolioNames = Array.isArray(newsUpdate.portfolios)
        ? newsUpdate.portfolios.map(portfolio => portfolio.name).join(', ')
        : 'No portfolios'; // Fallback if portfolios is not an array

      dataTableBasic.rows.add([
        [
          newsUpdate.title,
          newsUpdate.message.substr(0, 60)+"...",
          portfolioNames,
          new Date(newsUpdate.publishedAt).toLocaleDateString(),
          `<button class="view-button btn btn-sm bg-gradient-success" data-id="${newsUpdate.id}">View</button>`
        ],
      ]);
    });
  } else {
    console.log('No news updates available');
  }

  // Use event delegation for link clicks
//   document.querySelector("#datatable-basic").addEventListener("click", (event) => {
//     if (event.target.matches(".view-document")) { // Use matches for better compatibility
//       event.preventDefault(); // Prevent the default anchor behavior
//       const url = event.target.getAttribute("data-url");
//       console.log('Link clicked, URL:', url); // Log the URL to check if it's being retrieved
//       viewDocument(url); // Call the viewDocument function

//       const fullUrl = `${baseUrl}${url.startsWith('/') ? url : '/' + url}`; // Construct the full URL
//       router.push({ path: '/pdf-viewer', query: { url: fullUrl } }); // Use router.push to navigate
      
//     }
//   });
document.querySelector("#datatable-basic").addEventListener("click", (event) => {
    if (event.target.matches(".view-button")) { // Check if the clicked element is a View button
      const newsUpdateId = event.target.getAttribute("data-id"); // Get the ID of the news update
      const selectedArticle = store.newsUpdates.find(update => update.id === parseInt(newsUpdateId)); // Find the selected article
      openModal(selectedArticle); // Open the modal with the selected article
    }
  });

});
</script>
<template>
    <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">News & Updates</h5>
            <p class="mb-0 text-sm">
                News & Updates for different investment portfolios.
            </p>
          </div>
          <div class="table-responsive">

            <table id="datatable-basic" class="table table-flush">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Portfolio(s)</th>
                  <th>Date Published</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <!-- Rows will be added dynamically -->
              </tbody>
            </table>
            <Spinner :isLoading="store.isLoading" />
            <div v-if="error">{{ error }}</div>
            <Modal 
                v-if="isModalVisible" 
                :isVisible="isModalVisible" 
                :article="selectedArticle" 
                :fields="modalFields"
                @close="closeModal" />
          </div>
      </div>
    </div>
  </div>
  </div>


</template>