import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import router from '@/router';

const baseUrl = process.env.VUE_APP_BASE_URL;

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null);
  const isAuthenticated = ref(false);
  const error = ref(null);
  const isLoading = ref(false);

  const login = async (email, password) => {
    isLoading.value = true;
    try {
      const response = await axios.post(`${baseUrl}`+'/api/auth/local', {
        identifier: email,
        password: password,
      });
      user.value = response.data;
      // console.log(user.value);
      localStorage.setItem('token', user.value.jwt);
      localStorage.setItem('userId', user.value.user.id);
      isAuthenticated.value = true;
      error.value = null;
    } catch (err) {
      // error.value = err.response?.data?.message || 'Login failed';
      error.value = err.response?.data?.error?.message || 'Login failed';
      // console.log(err.response?.data.error.message);
      throw new Error(error.value);
    } finally {
      isLoading.value = false;
    }
  };

  const logout = async () => {
    user.value = null;
    isAuthenticated.value = false;
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    router.push('/authentication/signin');
  };

  return { user, isAuthenticated, error, isLoading, login, logout };
});
