import Swal from 'sweetalert2';

const sweetAlertService = {
  showAlert(type, options = {}) {
    console.log('Type:', type);
    console.log('Options:', options);
    const defaultOptions = {
      title: '',
      text: '',
      icon: type,
      showCloseButton: true,
      showCancelButton: false,
      confirmButtonText: 'OK',
      ...options,
    };

    return Swal.fire(defaultOptions);
  },

  showSuccess(title, text) {
    return this.showAlert('success', { title, text });
  },

  showError(title, text) {
    return this.showAlert('error', { title, text });
  },

  showWarning(title, text) {
    return this.showAlert('warning', { title, text });
  },

  showInfo(title, text) {
    return this.showAlert('info', { title, text });
  },

  showConfirmation(title, text) {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  },
};

export default sweetAlertService;
