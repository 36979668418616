import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_URL;

export const useAnnualFinancialsStore = defineStore('annualFinancials', () => {
  const financials = ref([]);
  const isLoading = ref(false);
  const error = ref(null);

  const fetchFinancials = async () => {
    isLoading.value = true;
    error.value = null;

    try {
      const token = localStorage.getItem('token'); // Get the token from local storage
      const response = await axios.get(`${baseUrl}`+'/api/annual-financials/?populate=*', {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });      
      // Map the response data to the desired format
      //console.log(response.data.data);
      financials.value = response.data.data.map(item => ({
        id: item.id,
        documentId: item.documentId,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        title: item.Title, // Include the Title field
        document: {
        //   name: item.Document.name,
          url: item.Document.url,
          mime: item.Document.mime,
        },
        portfolio: {
          name: item.portfolio.Name,
        },
      }));

    } catch (err) {
      error.value = err.response?.data?.message || 'Failed to fetch data'; // Use the error message from the response
    } finally {
      isLoading.value = false;
    }
  };

  return { financials, isLoading, error, fetchFinancials };
});
