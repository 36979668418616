import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_URL;

export const useNewsUpdatesStore = defineStore('newsUpdates', () => {
  const newsUpdates = ref([]);
  const isLoading = ref(false);
  const error = ref(null);

  const fetchNewsUpdates = async () => {
    isLoading.value = true;
    error.value = null;

    try {
      const token = localStorage.getItem('token'); 
      const response = await axios.get(`${baseUrl}`+'/api/news-and-updates/?populate=*&sort[0]=id:desc', {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });      
      // Mapping the response data to the desired format
      newsUpdates.value = response.data.data.map(item => ({
        id: item.id,
        publishedAt: item.publishedAt,
        title: item.Title, 
        message: item.Message,
        portfolios: item.portfolios.map(portfolio => ({
            name: portfolio.Name,
        })),
      }));

    } catch (err) {
      error.value = err.response?.data?.message || 'Failed to fetch data'; // Use the error message from the response
    } finally {
      isLoading.value = false;
    }
  };

  return { newsUpdates, isLoading, error, fetchNewsUpdates };
});
