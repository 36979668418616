<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';

import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "../../examples/Charts/GradientLineChart.vue";
import Carousel from "././components/Carousel.vue";
// import MemberCard from "./components/MemberCard.vue";
// import TodoListCard from "./components/TodoListCard.vue";
// import ProgressTrackCard from "./components/ProgressTrackCard.vue";
// import PostCard from "@/examples/Cards/PostCard.vue";
// import AuthorsTable from "./components/AuthorsTable.vue";
// import CategoriesList from "@/examples/Cards/CategoriesList.vue";
// import ProjectCard from "./components/ProjectCard.vue";
// import CreditCard from "./components/CreditCard.vue";
// import InfoCard from "./components/InfoCard.vue";

import image2 from "../../assets/img/img-2.jpg";
import image1 from "../../assets/img/img-1.jpg";
import image3 from "../../assets/img/img-3.jpg";

// import team1 from "../../assets/img/team-1.jpg";
// import team2 from "../../assets/img/team-2.jpg";
// import team5 from "../../assets/img/team-5.jpg";
// import team4 from "../../assets/img/team-4.jpg";

// import jira from "../../assets/img/small-logos/logo-jira.svg";
// import asana from "../../assets/img/small-logos/logo-asana.svg";
// import spotify from "../../assets/img/small-logos/logo-spotify.svg";
// import bootstrap from "../../assets/img/small-logos/bootstrap.svg";
// import invision from "../../assets/img/small-logos/logo-invision.svg";
// import slack from "../../assets/img/small-logos/logo-slack.svg";
// import adobe from "../../assets/img/small-logos/logo-xd.svg";

// import US from "../../assets/img/icons/flags/US.png";
// import DE from "../../assets/img/icons/flags/DE.png";
// import GB from "../../assets/img/icons/flags/GB.png";
// import BR from "../../assets/img/icons/flags/BR.png";

// const sales = {
//   us: {
//     country: "United States",
//     sale: 2500,
//     value: "$230,900",
//     bounce: "29.9%",
//     flag: US,
//   },
//   germany: {
//     country: "Germany",
//     sale: "3.900",
//     value: "$440,000",
//     bounce: "40.22%",
//     flag: DE,
//   },
//   britain: {
//     country: "Great Britain",
//     sale: "1.400",
//     value: "$190,700",
//     bounce: "23.44%",
//     flag: GB,
//   },
//   brasil: {
//     country: "Brasil",
//     sale: "562",
//     value: "$143,960",
//     bounce: "32.14%",
//     flag: BR,
//   },
// };
const baseUrl = process.env.VUE_APP_BASE_URL;
const token = localStorage.getItem('token');
const annualFinancialsCount = ref(0);
const financialModelsCount = ref(0);
const quarterlyReportsCount = ref(0);
const newsUpdatesCount = ref(0);
// const description = computed(() => {
//   return `See More <a href="/pages/annual-financials" class='text-sm font-weight-bolder text-success'>here</a>`;
// });

const fetchAnnualFinancials = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/annual-financials`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    annualFinancialsCount.value = response.data.data.length;
    localStorage.setItem('aFGraph', annualFinancialsCount.value);
  } catch (error) {
    console.error('Error fetching annual financials:', error);
  }
};

const fetchFinancialModels = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/financial-models`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    financialModelsCount.value = response.data.data.length;
    localStorage.setItem('fMGraph', financialModelsCount.value);
  } catch (error) {
    console.error('Error fetching financial models:', error);
  }
};

const fetchQuarterlyReports = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/quarterly-reports`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    quarterlyReportsCount.value = response.data.data.length;
    localStorage.setItem('qRGraph', quarterlyReportsCount.value)
  } catch (error) {
    console.error('Error fetching quarterly reports:', error);
  }
};

const fetchNewsUpdates = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/news-and-updates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    newsUpdatesCount.value = response.data.data.length;
    localStorage.setItem('nUGraph', newsUpdatesCount.value)
  } catch (error) {
    console.error('Error fetching news and updates:', error);
  }
};

// Functions to load all data
fetchFinancialModels();
fetchAnnualFinancials();
fetchQuarterlyReports();
fetchNewsUpdates();


// Computed property for chart data
const chartData = computed(() => ({
  labels: [
    'Financial Models',
    'Annual Financials',
    'Quarterly Reports',
    'News & Updates'
  ],
  datasets: [
    {
      label: 'Number of Records',
      data: [        
        localStorage.getItem('fMGraph'),
        localStorage.getItem('aFGraph'),
        localStorage.getItem('qRGraph'),
        localStorage.getItem('nUGraph')
      ],
    },
  ],
}));

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          
          <div class="col-lg-3 col-md-6 col-12">
            <router-link to="/pages/annual-financials">
              <mini-statistics-card
                title="Annual Financials"
                :value="`+${annualFinancialsCount}`"
                :description="`<span
                  class='text-sm font-weight-bolder text-success'
                  >See more</span>`"
                :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-primary',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
        
          <div class="col-lg-3 col-md-6 col-12">
            <router-link to="/pages/financial-models">
              <mini-statistics-card
                title="Financial Models"
                :value="`+${financialModelsCount}`"
                description="<span
                  class='text-sm font-weight-bolder text-success'
                  >See more</span>"
                :icon="{
                  component: 'ni ni-chart-bar-32',
                  background: 'bg-gradient-danger',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <router-link to="/pages/quarterly-reports">
              <mini-statistics-card
                title="Quarterly Reports"
                :value="`+${quarterlyReportsCount}`"
                :description="`<span
                  class='text-sm font-weight-bolder text-success'
                  >See more</span>`"
                :icon="{
                  component: 'ni ni-single-copy-04',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <router-link to="/news/news-updates">
              <mini-statistics-card
                title="News & Updates"
                :value="`+${newsUpdatesCount}`"
                :description="`<span
                  class='text-sm font-weight-bolder text-success'
                  >See more</span>`"
                :icon="{
                  component: 'ni ni-email-83',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }"
              />
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <gradient-line-chart
              id="chart-line"
              title="Overview"
              description="<i class='fa fa-chart-line text-primary'></i> A visual presentation of the number of records to date"
              :chart="chartData"
            />
          </div>
          <div class="col-lg-5">
            <carousel
              :items="[
                {
                  img: image2,
                  title: 'Get started with Nurture Investors Portal',
                  description:
                    'Use this Portal to better understand opportunities, investments, and track growth effortlessly.',
                  icon: {
                    component: 'ni ni-button-play text-dark',
                    background: 'bg-white',
                  },
                },
                {
                  img: image1,
                  title: 'Access Your Annual Financials with Ease',
                  description:
                    'Stay informed and make data-driven decisions with clear, detailed annual financial reports at your fingertips.',
                  icon: {
                    component: 'ni ni-single-copy-04 text-dark',
                    background: 'bg-white',
                  },
                },
                {
                  img: image3,
                  title: 'Stay Updated with Quarterly Reports',
                  description:
                    'Gain timely insights and track performance with comprehensive quarterly reports for better financial planning.',
                  icon: {
                    component: 'ni ni-single-copy-04 text-dark',
                    background: 'bg-white',
                  },
                },
                {
                  img: image1,
                  title: 'Build Confidence with Robust Financial Models',
                  description:
                    'Empower your investment strategy with accurate, customizable financial models designed to forecast and optimize performance.',
                  icon: {
                    component: 'ni ni-single-copy-04 text-dark',
                    background: 'bg-white',
                  },
                },                
              ]"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
