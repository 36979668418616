<script setup>
import { computed } from "vue";
import { useStore } from "@/stores/index";
const store = useStore();

const isRTL = computed(() => store.isRTL);
</script>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }},
            <a
              href="https://nurtureinvest.com"
              class="font-weight-bold"
              target="_blank"
              >Nurture Invest</a
            > | 
            All rights reserved.
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://distinctmedia.co.zw"
                class="nav-link pe-0 text-muted"
                target="_blank"
                >Developed by: <span class="font-weight-bold">Distinct</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
