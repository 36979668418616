<template>
  <div class="spinner-overlay" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

// Destructure isLoading directly from props
const { isLoading } = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
});
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(255, 255, 255, 0.8); */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #f6b105; /* Change this to your primary color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style> 