<script setup>
import { useRoute } from "vue-router";
// import { useStore } from "@/stores/index";

import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";

// const store = useStore();

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Dashboard" :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'Default' }" mini-icon="D" text="Home" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      

      <!-- PAGES -->

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Pages"
          :class="getRoute() === 'pages' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-ungroup text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'Annual Financials List' }" mini-icon="A" text="Annual Financials" />
              <sidenav-item :to="{ name: 'Financial Models List' }" mini-icon="F" text="Financial Models" />
              <sidenav-item :to="{ name: 'Quarterly Reports List' }" mini-icon="Q" text="Quarterly Reports" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

    <!-- END PAGES -->
      
    <!-- USER SETTINGS -->
<li class="nav-item">
        <sidenav-collapse
          collapse-ref="userExamples"
          nav-text="Communications"
          :class="getRoute() === 'news' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-ui-04 text-info text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'News and Updates List' }" mini-icon="K" text="News & Updates" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

    </ul>
  </div>
</template>
