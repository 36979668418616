<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="close">
      <div class="modal-content" :class="darkMode ? 'modal-content-bg-dark' : 'modal-content-bg-light'">
        <button class="close-button" :class="darkMode ? 'close-button-dark' : 'close-button-light'" @click="close"><span>X</span></button>
        <h2 :class="darkMode ? 'h2-dark' : 'h2-light'">{{ article.title }}</h2>
        <div v-for="field in fields" :key="field.key">
          <p>
            <strong>{{ field.label }}:</strong><br>
            {{ formatField(field.key, article[field.key]) }} <!-- Use the formatField method -->
          </p>
        </div>
        <p><strong>Portfolios:</strong> {{ portfolioNames }}</p>
      </div>
    </div>
  </template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import { useStore } from "@/stores/index";

const store = useStore();
const darkMode = computed(() => store.darkMode);

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  article: {
    type: Object,
    required: true,
  },
  fields: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close']);

const close = () => {
  emit('close');
};

const portfolioNames = computed(() => {
  return props.article.portfolios.map(portfolio => portfolio.name).join(', ');
});

// Function to format date to short date
const formatField = (key, value) => {
  if (key === 'publishedAt' || key === 'updatedAt') {
    return new Date(value).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
  return value;
};

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content-bg-light {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
}

.modal-content-bg-dark {
  background: #0f1241;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
}

.close-button-dark { color: #ffffff; }
.close-button-light { color: #333333}
.close-button {
    background: transparent;
    border: none;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    font-weight: bold;
}
.h2-dark { background: rgb(30 38 83); }
.h2-light { background: #eee; }
h2 { 
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0 30px 0;
 }
 p {
    font-size: 13px;
}

</style>
