<template>
    <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Document Viewer</h5>
            <!-- <p class="mb-0 text-sm">
              Annual Financials for different investment portfolios.
            </p> -->
            <p></p>

            <div class="pdf-viewer-page">
                <iframe v-if="pdfUrl" :src="pdfUrl" class="pdf-iframe" frameborder="0"></iframe>
                <div v-else>No PDF available</div>
            </div>

        </div>
    </div>
    </div>
</div>
</div>

</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const pdfUrl = ref(route.query.url); // Get the PDF URL from the query parameter

// Watch for changes in the route to update the PDF URL
watch(() => route.query.url, (newUrl) => {
  pdfUrl.value = newUrl; // Update the pdfUrl when the route changes
});

// Log the PDF URL to check if it's correct
console.log('PDF URL:', pdfUrl.value);
</script>

<style scoped>
.pdf-viewer-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pdf-iframe {
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  border: none; /* Remove border */
}
</style>