<script setup>
import { onMounted, ref } from 'vue';
import { useAnnualFinancialsStore } from '@/stores/annualFinancials';
import { DataTable } from 'simple-datatables';
import Spinner from '@/components/Spinner.vue';
import { useRouter } from 'vue-router'; // Import useRouter

const store = useAnnualFinancialsStore();
const pdfUrl = ref(null); // State to hold the PDF URL
const router = useRouter(); // Get the router instance

// Define the base URL
// const baseUrl = 'http://localhost:1337';
const baseUrl = process.env.VUE_APP_BASE_URL;


// Define the viewDocument function
const viewDocument = (url) => {
  const fullUrl = `${baseUrl}${url.startsWith('/') ? url : '/' + url}`; // Ensure correct URL formation
  console.log('Viewing document:', fullUrl); // Log the full URL to check if it's correct
  pdfUrl.value = fullUrl; // Set the PDF URL to display
};

onMounted(async () => {
  await store.fetchFinancials();

  // Initialize DataTable after data is fetched
  const dataTableBasic = new DataTable("#datatable-basic", {
    searchable: true,
    fixedHeight: true,
  });

  // Add rows to the DataTable
  store.financials.forEach((financial) => {
    dataTableBasic.rows.add([
      [
        financial.title,
        `<a href="#" class="view-document" data-url="${financial.document.url}">View Document</a>`, // Use anchor tag
        financial.portfolio.name,
        new Date(financial.createdAt).toLocaleDateString(),
        new Date(financial.updatedAt).toLocaleDateString(),
      ],
    ]);
  });

  // Use event delegation for link clicks
  document.querySelector("#datatable-basic").addEventListener("click", (event) => {
    if (event.target.matches(".view-document")) { // Use matches for better compatibility
      event.preventDefault(); // Prevent the default anchor behavior
      const url = event.target.getAttribute("data-url");
      console.log('Link clicked, URL:', url); // Log the URL to check if it's being retrieved
      viewDocument(url); // Call the viewDocument function

      // const fullUrl = `${baseUrl}${url.startsWith('/') ? url : '/' + url}`; // Construct the full URL

      // const fullGoogleUrl = 'https://docs.google.com/viewer?url='+`${fullUrl}`+'&embedded=true';

      router.push({ path: '/pdf-viewer', query: { url: url } }); // Use router.push to navigate
      
    }
  });
});
</script>
<template>
    <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Annual Financials</h5>
            <p class="mb-0 text-sm">
              Annual Financials for different investment portfolios.
            </p>
          </div>
          <div class="table-responsive">

            <table id="datatable-basic" class="table table-flush">
              <thead>
                <tr>
                  <th>Document Title</th>
                  <th>Document URL</th>
                  <th>Portfolio Name</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                <!-- Rows will be added dynamically -->
              </tbody>
            </table>
            <Spinner :isLoading="store.isLoading" />
            <div v-if="error">{{ error }}</div>
    <!-- PDF Viewer -->
    <PdfViewer v-if="pdfUrl" :pdfUrl="pdfUrl" />
          </div>
      </div>
    </div>
  </div>
  </div>


</template>
